<template>
  <section class="w-full">
    <CardAuditoria :typeCompany="typeCompany" />
    <GraficaAuditoria :typeCompany="typeCompany" />
    <!-- <CardNotaTecnica /> -->
    <CardNotaTecnicaInfo :typeCompany="typeCompany" />
  </section>
</template>

<script>

import { computed, defineAsyncComponent, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    CardAuditoria: defineAsyncComponent(() => import('../components/cardAuditoria')),
    GraficaAuditoria: defineAsyncComponent(() => import('../components/graficaAuditoria')),
    // CardNotaTecnica: defineAsyncComponent(() => import('../components/cardNotaTecnica')),
    CardNotaTecnicaInfo: defineAsyncComponent(() => import('../components/cardNotaTecnicaInfo'))
  },
  setup () {
    const route = useRoute()

    const filters = reactive({
      year: new Date().getFullYear(),
      month_start: '01',
      month_end: '12',
      department: null,
      service: null,
      client: null
    })

    const typeCompany = computed(() => {
      return parseInt(route.params.type ?? 3)
    })

    onMounted(() => {
      console.log('typeCompany', typeCompany.value)
    })

    return {
      filters,
      typeCompany
    }
  }
}
</script>
